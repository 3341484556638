/// <reference lib="dom" />

loadClerk()
loadGtag()

async function loadClerk() {
  if (typeof window !== 'undefined' && typeof document !== 'undefined') {
    const TARGET = 'start-action'
    const clerkFrontendDomain = CLERK_FRONTEND_DOMAIN
    const version = CLERK_VERSION

    const isInstalled = 'Clerk' in window

    if (!isInstalled) {
      await installClerk(clerkFrontendDomain, version, TARGET)
    }

    await clerkHasLoaded()
    renderStartButton(TARGET, { isUser: !!window.Clerk?.user })
  }

  async function clerkHasLoaded() {
    if (window.Clerk?.loaded) return
    return new Promise<void>((resolve) =>
      window.Clerk?.addListener((v) => {
        if (typeof v.user !== 'undefined' || typeof v.session !== 'undefined') {
          resolve()
        }
      })
    )
  }

  function installClerk(
    clerkFrontendHostname: string,
    version: string,
    TARGET: string
  ) {
    return new Promise<void>((resolve) => {
      const src = new URL(
        `https://${clerkFrontendHostname}/npm/@clerk/clerk-js@${version}/dist/clerk.browser.js`
      )
      const script = document.createElement('script')
      script.src = src.href
      script.async = true
      script.setAttribute('data-clerk-frontend-api', clerkFrontendHostname)

      script.addEventListener('load', () =>
        resolve(
          window.Clerk?.load({}).then(() => {
            console.debug('[clerk]', 'loaded')
          })
        )
      )

      document.body.appendChild(script)
    })
  }

  function renderStartButton(target: string, props: { isUser: boolean }) {
    const container = document.getElementById(target)
    if (container) {
      const btn = document.createElement('button')
      btn.classList.add('btn')

      const span = document.createElement('span')
      span.innerText = props.isUser ? 'Meet A Need' : 'Get Started'

      btn.addEventListener('click', () => {
        document.location = props.isUser ? '/home' : '/start'
      })

      btn.replaceChildren(span)
      container.replaceChildren(btn)
      console.debug(
        '[clerk]',
        `rendered StartButton at #${container.id} with props ${JSON.stringify(
          props
        )}`
      )
    }
  }
}

function loadGtag() {
  if (
    GA_MEASUREMENT_ID_ALTERNATE &&
    typeof window !== 'undefined' &&
    typeof document !== 'undefined'
  ) {
    const src = new URL('https://www.googletagmanager.com/gtag/js')
    src.searchParams.set('id', GA_MEASUREMENT_ID_ALTERNATE)

    const script = document.createElement('script')
    script.src = src.href
    script.async = true

    window.dataLayer = window.dataLayer || []
    window.gtag = function (...args) {
      window.dataLayer.push(args)
    } as Gtag.Gtag

    script.addEventListener('load', () => {
      gtag('js', new Date())
      gtag('config', GA_MEASUREMENT_ID_ALTERNATE)
    })

    document.body.appendChild(script)
  }
}
